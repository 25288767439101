import React from "react";
import {
  ContainerContent,
  ContainerContentFixed,
  ContainerCta,
  ContentTitle,
  ExternalLink,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../../components";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import "./blog.css";

const Blog = () => {
  const data = useStaticQuery(graphql`
    query {
      BlogImage: file(relativePath: { eq: "blogs/blog4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO
        title={"How Energy Star Rating Works When Building Your Home (A Guide)"}
      />
      <ContainerContent style={{ padding: 0 }}>
        <Img
          fluid={data.BlogImage.childImageSharp.fluid}
          style={{ marginBottom: "8px", maxHeight: 500 }}
        />
      </ContainerContent>
      <ContainerContent>
        <ContainerContentFixed>
          <ContentTitle
            title={
              "How Energy Star Rating Works When Building Your Home (A Guide)"
            }
          />
          <div className={"cp-blog"}>
            <p>
              <b>A star rating for your new home others only wish for</b>
            </p>

            <p>
              Everyone dreams of a home of their own. Having a new home built
              for you makes it all the more special.{" "}
            </p>

            <p>
              There are many things to consider and one you cannot afford to
              overlook is the energy rating of your new home.
            </p>

            <p>
              <h4>
                How does the Energy Rating work when you build a new home?{" "}
              </h4>
            </p>

            <p>Let’s take you through it.</p>

            <p>
              First off, the Energy Rating System was introduced as part of the
              Building Code of Australia. As of 2004, new Victorian homes were
              required to meet a 5 Star energy rating. Since 2010, this was
              raised to a 6 Star energy rating.{" "}
            </p>

            <p>What does that mean for your new home? </p>

            <p>It’s now compulsory for all new homes to have: </p>

            <ul>
              <li>A 6 Star energy rating for the building fabric, and</li>
              <li>
                A rainwater tank for toilet flushing or a solar hot water
                system, and
              </li>
              <li>Water efficient shower heads and tapware</li>
            </ul>

            <p>
              That all seems pretty simple but there are other factors to
              consider.
            </p>

            <p>
              The position and size of your new home is important for energy
              efficiency. This is where a custom home builder in Melbourne can
              really make a difference.
            </p>

            <p>
              Knowing how to position your home on your block and where it faces
              can mean a big difference in your energy use.{" "}
            </p>

            <p>
              <h4>Added energy efficiency</h4>
            </p>

            <p>
              There are other factors that can make a huge difference to your
              energy rating and your energy bills.{" "}
            </p>

            <p>
              Choosing energy efficient lighting, heating &amp; cooling and
              appliances will mean substantial energy savings and a more
              eco-friendly home.
            </p>

            <p>
              It’s the basics of the building itself that are crucial to
              achieving a higher rating.
            </p>

            <p>
              There is no point in fitting your home with the top energy rated
              appliances and lighting if the building itself isn’t made of
              energy efficient materials with an energy efficient design.
            </p>

            <p>
              A custom home builder in Melbourne should know the climate of the
              area of Melbourne in which you are building and be able to
              construct your new home in the best position.
            </p>

            <p>
              The design of the home will cater to all the key factors of the
              energy rating including the materials used in the type of roofing,
              walls, flooring and more.
            </p>

            <p>
              A larger home needs to increase its energy efficiency. This is
              where the help of a Double Storey custom home builder really pays
              off.{" "}
            </p>

            <p>
              <h4>What does the Energy Star Rating achieve?</h4>
            </p>

            <p>
              The Energy Start Rating system was devised to make each and every
              new home as energy efficient as possible.
            </p>

            <p>
              The rating estimates the annual energy load for heating and
              cooling your house to keep it at a comfortable temperature to live
              in.{" "}
            </p>

            <p>Does it matter?</p>

            <p>
              It certainly does. Here are a few important reasons to consider;
            </p>

            <ol>
              <li>
                Your energy bills will be reduced significantly when you achieve
                a high rating, e.g. going up one star in the rating can mean up
                to a 30% saving on your energy costs.
              </li>
              <li>
                You can ensure your home has a better resale value by making it
                as energy efficient as possible.
              </li>
              <li>
                Improve your sustainability and reduce your environmental
                impact.
              </li>
              <li>Less strain on the electricity supply.</li>
            </ol>

            <p>
              With good design, especially at the planning stage, you can
              increase your chances of a higher energy star rating.
            </p>

            <p>
              For more details on the Energy Star Rating and how to make your
              home more energy efficient you can read the{" "}
              <ExternalLink
                url={
                  "http://www.yourhome.gov.au/house-designs/design-principles"
                }
              >
                Federal Govt Your House Design Principles
              </ExternalLink>{" "}
              and the{" "}
              <ExternalLink url="https://www.sustainability.vic.gov.au/You-and-your-home/Building-and-renovating/Energy-Smart-Housing-Manual">
                Sustainability Victoria’s Energy Smart Housing Manual
              </ExternalLink>
              . Both have detailed information on achieving the best in energy
              ratings.
            </p>

            <p>
              One key factor is knowing the local area of where your home will
              be built. It’s crucial to building the right home for the location
              and making sure it suits the climate of that area.
            </p>

            <p>
              With a quality custom home builder Melbourne based and experienced
              with local conditions, you have an added advantage on other home
              buyers and a better chance of a better energy star rating.
            </p>

            <p>
              <h4>The Grandeur Homes difference</h4>
            </p>

            <p>
              That’s the difference that Grandeur Homes offer their clients.
            </p>

            <p>
              You get more than a truly wonderful home built for you and your
              family, you have the experts on your side who can help make sure
              your energy star rating is one to be proud of.
            </p>

            <p>
              With Integrity as their number one value, you will always know
              that the Grandeur Group are working for you and have your best
              interests at the heart of everything they do.
            </p>

            <p>
              Talk to the team who are dedicated to building a quality home for
              you and your family. Get your dream home happening –{" "}
              <InternalLink url={InternalLinkTypes.Contact}>
                Contact Grandeur Homes{" "}
              </InternalLink>
              today.
            </p>
          </div>
        </ContainerContentFixed>
      </ContainerContent>
      <section>
        <ContainerCta
          title={"Your future happiness is waiting"}
          subTitle={"Start building your custom Grandeur Home"}
          primaryLink={InternalLinkTypes.GetStarted}
          primaryText={"Get Started"}
          secondaryLink={InternalLinkTypes.Blogs}
          secondaryText={"Read other blogs"}
        />
      </section>
    </Layout>
  );
};

export default Blog;
